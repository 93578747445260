<template>
  <div>
    <p class="title">
      {{ $t('login.title')}}
    </p>
    <div class="tab">
      <!-- <div
        @click="iphoneState('phone')"
        :class="{ iphoneState: iphone === 'phone' }"
      >
        Phone Number
      </div> -->
      <!-- <div @click="iphoneState('yx')" :class="{ iphoneState: iphone === 'yx' }">
        EMail address
      </div> -->
    </div>
    <!-- 手机号登录 -->
    <!-- <div
      v-if="iphone == 'phone'"
      :class="[
        'login-wrapper',
        { 'pop-up': isPopupKeyboard, 'pack-up': isPackupKeyboard },
      ]"
    > -->
    <!-- 输入手机号、验证码 -->
    <!-- <van-cell-group>
        <van-field
          v-model="mobile"
          type="digit"
          :border="false"
          maxlength="11"
          @update:model-value="handleValidMobile"
          :class="[{ 'van-cells': changeColor }]"
          @focus="changeFlag(true, 1)"
          @blur="changeFlag(false, 1)"
          clearable
          placeholder="Phone Number"
        >
          <template #button>
            <van-popover
              v-model:show="sp"
              trigger="click"
              :actions="actions"
              placement="bottom-end"
              :overlay="true"
              @select="onSelect"
            >
              <template #reference>
                <van-button type="primary" @click="kg">中国</van-button>
                <div @click="kg">{{ gj }}</div>
              </template>
            </van-popover>
          </template>
        </van-field>
        <van-field
          style="margin-top:10px;"
          :class="[{ 'van-cells': changeColor2 }]"
          v-model="password"
          :type="passwordShow ? 'text' : 'password'"
          @update:model-value="handleValidPassword"
          placeholder="Password"
        >
          <template #button>
            <van-icon
              :name="passwordShow ? 'eye-o' : 'closed-eye'"
              @click="passwordShow = !passwordShow"
            />
          </template>
        </van-field>
      </van-cell-group> -->
    <!-- 登录按钮 -->
    <!-- <div class="login-box">
        <van-Button
          @click="submit"
          :class="{ 'login-btn-disable': isDisableSubmit }"
          class="login-btn"
          :disabled="isDisableSubmit"
          >Log In</van-Button
        >
      </div>
      <div class="sign" @click="toSign('phone')">
        No account yet? Sign
      </div>
      <van-loading class="buttonLoading" v-if="isSubmiting" size="36px" vertical
        >Log in...</van-loading
      >
    </div> -->
    <!-- 邮箱登录 -->
    <div
      v-show="iphone == 'yx'"
      :class="[
        'login-wrapper',
        { 'pop-up': isPopupKeyboard, 'pack-up': isPackupKeyboard },
      ]"
    >
      <!-- 输入邮箱、验证码 -->
      <van-cell-group>
        <van-field
          v-model="email"
          type="email"
          :border="false"
          @update:model-value="handleValidEmail"
          :class="[{ 'van-cells': changeColor }]"
          @focus="changeFlag(true, 1)"
          @blur="changeFlag(false, 1)"
          clearable
          :placeholder="$t('login.input1')"
        />
        <van-field
          style="margin-top:10px;"
          :class="[{ 'van-cells': changeColor2 }]"
          v-model="password"
          :type="passwordShow ? 'text' : 'password'"
          @update:model-value="handleValidPassword"
          :placeholder="$t('login.input2')"
        >
          <template #button>
            <img
              style="width:16px;margin-top:5px;"
              v-if="passwordShow"
              @click="passwordShow = !passwordShow"
              src="../../assets/icon/ok.png"
              alt=""
            />
            <img
              style="width:16px;margin-top:5px;"
              v-else
              @click="passwordShow = !passwordShow"
              src="../../assets/icon/no.png"
              alt=""
            />
            <!-- <van-icon
              :name="passwordShow ? 'eye-o' : 'closed-eye'"
              @click="passwordShow = !passwordShow"
            /> -->
          </template>
        </van-field>
      </van-cell-group>
      <div class="forgot" @click="this.$router.push('/setPass')">
        {{ $t("login.button3") }}
      </div>
      <!-- 登录按钮 -->
      <div class="login-box">
        <van-Button
          @click="emailSubmit"
          :class="{ 'login-btn-disable': isEmailDisableSubmit }"
          class="login-btn"
          :disabled="isEmailDisableSubmit"
        >
          {{ $t("login.button1") }}</van-Button
        >
      </div>
      <div class="sign" @click="toSign('yx')">
        {{ $t("login.button2") }}
      </div>
      <van-loading
        class="buttonLoading"
        v-if="isSubmiting"
        size="36px"
        vertical
      >
        {{ $t("login.button1") }}...</van-loading
      >
    </div>
  </div>
</template>

<script>
import { getPlatform } from "../../utils"
// import Cookies from 'js-cookie'
import md5 from "js-md5"
import Button from "@/components/public/Button"
export default {
  name: "login",
  components: {
    Button,
  },
  data() {
    return {
      gj: "中国",
      gjId: "86",
      sp: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: "中国", id: 86 },
        { text: "马来西亚", id: 60 },
      ],
      passwordShow: false,
      iphone: "yx",
      buttonFlag: true,
      changeColor: false,
      changeColor2: false,
      // from: this.$route.query.from || '', // 如果从业务页面跳转而来, 登录后需回跳
      lastRouteName: null, // 需要回跳的路由名称
      mobile: null, // 手机号
      isValidMobile: false, // 是否为有效手机号
      isSubmiting: false, // 调用提交登录接口直至路由跳转之前禁用提交按钮
      isPopupKeyboard: false, // 是否弹出软键盘
      isPackupKeyboard: false, // 是否收起软键盘
      password: null, // 密码
      isValidPassword: false, // 是否为有效密码
      isValidEmail: false, // 是否为有效邮箱
    }
  },
  computed: {
    // 是否禁用提交按钮
    isDisableSubmit() {
      if (!this.isValidMobile || !this.isValidPassword) {
        return true
      }
      return false
    },
    // 邮箱是否禁用提交按钮
    isEmailDisableSubmit() {
      if (!this.isValidEmail || !this.isValidPassword) {
        return true
      }
      return false
    },
  },
  mounted() {},
  methods: {
    onSelect(action) {
      this.gj = action.text
      this.gjId = action.id
    },
    toSign(type) {
      this.$router.push({
        path: "/sign",
        query: {
          type: type,
        },
      })
    },
    iphoneState(state) {
      this.iphone = state
      this.mobile = null
      this.isValidMobile = false
      this.isSubmiting = false
      this.password = null
      this.isValidPassword = false
      this.isValidEmail = false
    },
    changeFlag(flag, type) {
      if (type === 1) {
        this.changeColor = flag
      } else {
        this.changeColor2 = flag
      }
    },
    // 验证手机号输入
    handleValidMobile(val) {
      if (!this.mobile || this.mobile.length < 11) {
        this.isValidMobile = false
        return
      }
      const phoneReg = /^1[3456789]\d{9}$/
      if (!phoneReg.test(this.mobile)) {
        this.$toast("手机号错误")
        return
      }
      this.isValidMobile = true
    },
    // 验证邮箱输入
    handleValidEmail(val) {
      if (!this.email || this.email.length < 1) {
        this.isValidEmail = false
        return
      }
      const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
      if (!emailReg.test(this.email)) {
        this.$toast("Mailbox format error")
        return
      }
      this.isValidEmail = true
    },
    // 验证密码输入
    handleValidPassword(val) {
      if (!this.password || this.password.length < 6) {
        this.isValidPassword = false
        return
      }
      const passwordReg = /^(\w){6,20}$/
      if (!passwordReg.test(this.password)) {
        this.$toast(
          "The password can contain only 6 to 20 letters, digits, and underscores"
        )
        return
      }
      this.isValidPassword = true
    },
    // 提交登录
    submit() {
      this.isSubmiting = true
      this.isDisableSubmit = true
      const postData = {
        mobile: this.gjId + this.mobile,
        password: md5(this.password),
      }
      this.$request
        .post("/common/loginByMobile", postData)
        .then(
          (res) => {
            if (res.code == 200) {
              localStorage.setItem("token", res.data.token)
              localStorage.setItem("mobile", res.data.mobile)
              this.$toast.success({
                message: "Login successful",
                duration: 1000,
              })
              this.$router.push({ path: "/" })
            } else if (res.code === -1) {
              localStorage.removeItem("code") ///
              this.$router.push("/") ///
            } else {
              this.isSubmiting = false
              this.isDisableSubmit = false
              this.$toast(res.msg)
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },
    // 邮箱提交登录
    emailSubmit() {
      this.isSubmiting = true
      this.isDisableSubmit = true
      const postData = {
        email: this.email,
        password: md5(this.password),
      }
      this.$request
        .post("/common/loginByEmail", postData)
        .then(
          (res) => {
            if (res.code == 200) {
              localStorage.setItem("token", res.data.token)
              localStorage.setItem("mobile", res.data.mobile)
              this.$toast.success({
                message: res.msg,
                duration: 1000,
              })
              this.$router.push({ path: "/" })
            } else if (res.code === -1) {
              localStorage.removeItem("code") ///
              this.$router.push("/") ///
            } else {
              this.isSubmiting = false
              this.isDisableSubmit = false
              this.$toast(res.msg)
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((error) => {
          this.$toast("error")
        })
    },
    // 跳转到用户服务协议
    toUserAgreement() {
      this.$router.push("/UserReg")
    },
    // 跳转到隐私协议
    toPrivacyAgreement() {
      this.$router.push("/Privacy")
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '../../assets/style/common.scss';
@keyframes moveUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-160px);
  }
}
@keyframes moveDown {
  from {
    transform: translateY(-160px);
  }
  to {
    transform: translateY(0);
  }
}

.title {
  width: 100%;
  padding: 40px 0 40px;
  text-align: center;
  font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
  font-weight: 700;
  font-size: 30px;
  color: #333333;
}
.tab {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 50px;
  font-weight: 600;
  font-size: 16px;
  color: #bbbbbb;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  justify-content: space-between;
  div {
    border: 0;
    position: relative;
  }
  .iphoneState {
    font-size: 20px;
    color: #333333;
  }
  .iphoneState:after {
    content: "";
    position: absolute;
    left: 30%;
    bottom: 0px;
    height: 8px;
    width: 28px;
    background-color: #f8de4d;
  }
}
.login-wrapper {
  width: 100%;
  padding: 30px;
  &.pop-up {
    animation: moveUp 0.3s 1;
    animation-fill-mode: forwards;
  }
  &.pack-up {
    animation: moveDown 0.3s 1;
    animation-fill-mode: forwards;
  }
  .buttonLoading {
    position: absolute;
    z-index: 9;
    top: 44%;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .image-box {
    margin-top: 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      width: 76px;
      height: 76px;
      // @include background-img('~@/assets/me/logo_duyou.svg');
    }
    .text {
      margin-top: 6px;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .van-cell-group {
    // margin-top: 50px;
    color: #d7d8e1;
    .van-cells {
      // border-bottom: 1px solid #1677ff !important;
      background: #f8f8f8;
      border-radius: 26px;
    }
    .van-cell {
      background: #f8f8f8;
      border-radius: 26px;
      // font-size: 24px;
      // color: #3f4047;
      // padding: 15px 0;
      // border-bottom: 1px solid #dee3ff;
      // font-weight: 700;
      // .van-field__button {
      //   padding-left: 0;
      //   margin-left: 8px;
      //   color: #d7d8e1;
      .sms-btn {
        font-size: 14px;
        color: #1677ff;
        font-weight: 700;
        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          // opacity: 0.5;
          color: #d7d8e1;
        }
        &.again {
          color: #1677ff;
        }
      }
      // }
    }
  }
  .van-hairline--top-bottom::after {
    border: none;
  }
  .forgot {
    margin: 10px 0 23px;
    font-size: 12px;
    color: #4a4a4a;
    text-align: right;
    font-weight: 500;
  }
  .login-box {
    margin-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .login-btn {
      width: 315px;
      height: 49px;
      font-size: 16px;
      color: #4a4a4a;
      font-weight: 700;
      background: #f8de4d;
      border-radius: 25px;
    }
    .login-btn-disable {
      background: #e1e1e1;
      color: #999999;
    }
    .tips {
      align-self: flex-start;
      margin-top: 16px;
      font-size: 12px;
      color: #999;
      .agreements {
        color: #ff6450;
      }
    }
  }
}
.sign {
  width: 100%;
  height: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #4a4a4a;
  text-align: center;
  font-style: normal;
  text-decoration-line: underline;
}
</style>

<style lang="scss">
::placeholder {
  font-size: 14px;
  font-weight: 700;
}
.van-field__control {
  color: #4a4a4a;
}
</style>
